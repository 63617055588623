import React from 'react';
import PropTypes from 'prop-types';
import Style from './water-color.module.scss';
import accentBottomBlush from '../../../assets/images/brush-bottom-blush.png';
import accentTopBlush from '../../../assets/images/brush-top-blush.png';
import accentTopTeal from '../../../assets/images/brush-top-teal.png';
import accentBottomTeal from '../../../assets/images/brush-bottom-teal.png';
import accentBottomBlushDesktop from '../../../assets/images/brush-bottom-blush-desktop.png';
import accentTopBlushDesktop from '../../../assets/images/brush-top-blush-desktop.png';
import accentTopTealDesktop from '../../../assets/images/brush-top-teal-desktop.png';
import accentBottomTealDesktop from '../../../assets/images/brush-bottom-teal-desktop.png';

const WaterColor = ({ paintColor, bgColorClass, children }) => {
  const BACKGROUND = {
    blush: { top: accentTopBlush, bottom: accentBottomBlush },
    teal: { top: accentTopTeal, bottom: accentBottomTeal }
  };

  const BACKGROUND_DESKTOP = {
    blush: { top: accentTopBlushDesktop, bottom: accentBottomBlushDesktop },
    teal: { top: accentTopTealDesktop, bottom: accentBottomTealDesktop }
  };

  return (
    <div
      className={`grid-container full watercolor ${Style.sectionWaterColor} ${bgColorClass}`}
    >
      <div className={Style.accentContainer}>
        <div
          className={`mobile-only ${Style.accent} ${Style.accentTop}`}
          style={{ backgroundImage: `url(${BACKGROUND[paintColor].top})` }}
        />
        <div
          className={`mobile-only ${Style.accent} ${Style.accentBottom}`}
          style={{ backgroundImage: `url(${BACKGROUND[paintColor].bottom})` }}
        />
        <div
          className={`desktop-only ${Style.accent} ${Style.accentTop}`}
          style={{
            backgroundImage: `url(${BACKGROUND_DESKTOP[paintColor].top})`
          }}
        />
        <div
          className={`desktop-only ${Style.accent} ${Style.accentBottom}`}
          style={{
            backgroundImage: `url(${BACKGROUND_DESKTOP[paintColor].bottom})`
          }}
        />
      </div>

      {children}
    </div>
  );
};

WaterColor.propTypes = {
  paintColor: PropTypes.string.isRequired,
  bgColorClass: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default WaterColor;
