/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slider from 'react-slick';
import Style from './carousel.module.scss';

const Carousel = ({ children, imposedSettings, baseSettings }) => {
  const settings = imposedSettings
    ? { ...baseSettings, ...imposedSettings }
    : baseSettings;

  return (
    <Slider {...settings} className={Style.slickSlider}>
      {children}
    </Slider>
  );
};

Carousel.defaultProps = {
  baseSettings: {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
};
export default Carousel;
