/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import Carousel from '..';
import Style from './join-carousel.module.scss';
import GridX from '../../grid-x';
import JoinOfferCard from '../../join-offer-card/index';
import STRIPE_PLANS_QUERY from '../../data/plans';
import Loader from '../../ui/loader';
import { GET_COUPON } from '../../../graphql/coupon/schema';

const numberOfSlides = 3;
const slideWidth = 325;
const maxWidth = numberOfSlides * slideWidth;
const settings = {
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: numberOfSlides,
  slidesToScroll: numberOfSlides,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        centerMode: true,
        slidesToShow: 1,
        vertical: true
      }
    }
  ]
};

const JoinCarousel = ({ disable }) => {
  const isDisabled = disable && !localStorage.getItem('tiu_coupon');

  const {
    data: {
      Coupon: { coupon }
    }
  } = useQuery(GET_COUPON);
  const { error, loading, data } = useQuery(STRIPE_PLANS_QUERY);
  if (error) return <div>Error</div>;
  if (loading) return <Loader />;

  let hasCoupon = false;
  let discountedPlans = null;
  let productMetadata = null;
  if (data.retrieveStripeProduct.data) {
    data.retrieveStripeProduct.data.forEach((p) => {
      // hardcoded Stripe product IDs (prod + test) to prevent subhub from breaking if any unauthorized Stripe products are created
      if (p.id === 'prod_GYNbtpswmIma2d' || p.id === 'prod_GXeJXj6tkN3w0i') {
        productMetadata = p.metadata;
      }
    });
  }
  const swapElement = (plans, indexA, indexB) => {
    const tmp = plans[indexA];
    plans[indexA] = plans[indexB];
    plans[indexB] = tmp;
  };
  let plans = null;
  if (data.retrieveStripePlans.data) {
    plans = Array.from(data.retrieveStripePlans.data).reverse();
    const quarterlyIndex = plans.findIndex((e) => e.nickname === 'Quarterly');
    swapElement(plans, quarterlyIndex, 1);
  }

  if (coupon) {
    discountedPlans = plans.filter((p) => {
      const { associated_coupons } = p.metadata;
      const normalizedAssociatedCoupons = associated_coupons.map((c) =>
        c.toLowerCase()
      );
      // check if this plan can use this coupon
      const discountedPlan = normalizedAssociatedCoupons.includes(coupon);
      if (discountedPlan) {
        hasCoupon = true;
        return p;
      }
      return null;
    });
  }
  const DISPLAY_PLANS = {
    true: discountedPlans,
    false: plans
  };

  return (
    <>
      <div
        className={`carousel-container ${Style.container}`}
        style={{ maxWidth }}
      >
        <Carousel imposedSettings={settings}>
          {DISPLAY_PLANS[hasCoupon] ? (
            DISPLAY_PLANS[hasCoupon].map((plan) => (
              <div className={Style.slide} key={plan.id} data-test="plan-card">
                <JoinOfferCard
                  plan={plan}
                  productMetadata={productMetadata}
                  showTrialButton
                  disable={isDisabled}
                />
              </div>
            ))
          ) : (
            <div> No Plans </div>
          )}
        </Carousel>
      </div>
      {isDisabled && (
        <div>
          <h5 style={{ color: 'red', marginLeft: '25px' }}>
            Don&apos;t miss out! Make sure you apply your promo code
          </h5>
        </div>
      )}
    </>
  );
};

JoinCarousel.propTypes = {
  disable: PropTypes.bool.isRequired
};

export default JoinCarousel;
