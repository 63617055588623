import React from 'react';
import Style from './hero.module.scss';
import GridX from '../grid-x';
import COPY from '../copy';

class Hero extends React.PureComponent {
  render() {
    return (
      <GridX
        containerClasses={`hero-section full ${Style.bgWrapper}`}
        gridClasses={`grid-margin-x ${Style.gridStyle}`}
      >
        <div className={`cell medium-8 medium-offset-2 ${Style.heading}`}>
          <div
            className={`hero-section-text heading fade-in ${Style.textWrapper}`}
          >
            <h2>{COPY.homepage.hero.headline}</h2>
            <h2>{COPY.homepage.hero.subheading}</h2>
            <p>{COPY.homepage.hero.copy}</p>
          </div>
        </div>
      </GridX>
    );
  }
}

export default Hero;
