import React from 'react';
import Sticky from 'react-sticky-el';
import { Link } from 'gatsby';
import Style from './sign-up-button.module.scss';

const SignUpButton = () => {
  const stickyStyle = {
    paddingBottom: '20px',
    zIndex: 2
  };

  return (
    <Sticky
      stickyStyle={stickyStyle}
      mode="bottom"
      className="sign-up-button mobile-only"
    >
      <div className={Style.signUpContainer}>
        <Link to="/pick-plan" className="button primary expanded">
          Start Free Trial!
        </Link>
      </div>
    </Sticky>
  );
};

export default SignUpButton;
