import React from 'react';
import Layout from '../components/layout';
import SignUpButton from '../components/sign-up-button';
import Hero from '../components/hero';
import TestimonialCarousel from '../components/carousel/testimonial-carousel';
import JoinCarousel from '../components/carousel/join-carousel';
import SectionHeading from '../components/section-heading';
import GridX from '../components/grid-x';
import WaterColor from '../components/ui/water-color';
import Coupon from '../components/coupon';
import COPY from '../components/copy';

import imgFull from '../assets/images/img-full.jpg';
import imgFullDesktop from '../assets/images/img-full-desktop.jpg';
import imgLiveClasses from '../assets/images/img-live-classes.jpg';
import imgBowl from '../assets/images/img-bowl.jpg';
import imgCommunity from '../assets/images/img-community.jpg';
import asSeenOnLogos from '../assets/images/as-seen-on-logos.jpg';
import asSeenOnLogosDesktop from '../assets/images/as-seen-on-logos-desktop.jpg';
import workoutVideo from '../assets/video/workout.mp4';
import WorkoutVideoPoster from '../assets/video/workout.jpg';
import onDemandVideo from '../assets/video/on_demand.mp4';
import onDemandVideoPoster from '../assets/video/on_demand.jpg';

// eslint-disable-next-line react/prop-types
const IndexPage = () => (
  <Layout logoColor="#fff" textColor="#fff">
    <div className="container home">
      <div className="sign-up-scroll-container">
        <Hero />

        <GridX gridClasses="grid-padding-x grid-padding-y align-middle">
          <div className="cell" />
          <div className="cell medium-6">
            <SectionHeading
              heading={COPY.homepage.panels.panelOne.headline}
              paragraph={COPY.homepage.panels.panelOne.copy}
              mobileTextCenter
              maxWidth
            />
          </div>
          <div className="cell medium-6 fade-in">
            <video
              src={workoutVideo}
              type="video/mp4"
              poster={WorkoutVideoPoster}
              playsInline
              muted
              autoPlay
              loop
              preload
            />
          </div>
        </GridX>

        <WaterColor paintColor="teal" bgColorClass="bg-teal-tone-4">
          <GridX gridClasses="grid-padding-x grid-padding-y align-middle flex-dir-row-reverse">
            <div className="cell medium-6">
              <SectionHeading
                heading={COPY.homepage.panels.panelTwo.headline}
                paragraph={COPY.homepage.panels.panelTwo.copy}
                headingColorClass="text-blue-tone-3"
                mobileTextCenter
                maxWidth
              />
            </div>

            <div className="cell medium-6 fade-in">
              <img src={imgBowl} alt="" />
            </div>
          </GridX>
        </WaterColor>

        <GridX gridClasses="grid-padding-x grid-padding-y align-middle">
          <div className="cell" />
          <div className="cell medium-6">
            <SectionHeading
              heading={COPY.homepage.panels.panelThree.headline}
              paragraph={COPY.homepage.panels.panelThree.copy}
              mobileTextCenter
              maxWidth
            />
          </div>
          <div className="cell medium-6 fade-in">
            <video
              src={onDemandVideo}
              type="video/mp4"
              poster={onDemandVideoPoster}
              playsInline
              muted
              autoPlay
              loop
              preload
            />
          </div>

          <div className="cell" />
        </GridX>

        <WaterColor paintColor="blush" bgColorClass="bg-blush-tone-1">
          <GridX gridClasses="grid-padding-x grid-padding-y align-middle flex-dir-row-reverse">
            <div className="cell medium-6">
              <SectionHeading
                heading={COPY.homepage.panels.panelFour.headline}
                paragraph={COPY.homepage.panels.panelFour.copy}
                headingColorClass="text-blue-tone-3"
                mobileTextCenter
                maxWidth
              />
            </div>
            <div className="cell medium-6 fade-in">
              <img src={imgLiveClasses} alt="" />
            </div>
          </GridX>
        </WaterColor>
        <GridX gridClasses="grid-padding-x grid-padding-y align-middle">
          <div className="cell" />
          <div className="cell medium-6">
            <SectionHeading
              heading={COPY.homepage.panels.panelFive.headline}
              paragraph={COPY.homepage.panels.panelFive.copy}
              mobileTextCenter
              maxWidth
            />
          </div>
          <div className="cell medium-6 fade-in">
            <img src={imgCommunity} alt="" />
          </div>
          <div className="cell" />
        </GridX>

        <GridX
          containerClasses="full bg-white-tone-1"
          gridClasses="grid-padding-y carousel-testimonial"
        >
          <div className="cell" />
          <div className="cell medium-6 medium-offset-3 large-4 large-offset-4 no-padding">
            <h3 className="text-center text-blue-tone-3">
              {COPY.carousels.testimonials.headline}
            </h3>
          </div>
          <div className="cell small-10 small-offset-1 medium-8 medium-offset-2">
            <TestimonialCarousel />
          </div>
          <div className="cell" />
        </GridX>

        <div className="image-full">
          <img src={imgFull} className="mobile-only" alt="" />
          <img src={imgFullDesktop} className="desktop-only" alt="" />
        </div>

        <SignUpButton />
      </div>

      <GridX
        containerClasses="full bg-white-tone-1"
        gridClasses="grid-padding-y carousel-join"
      >
        <div className="cell" />
        <div className="cell text-center">
          <h2 className="text-blue-tone-3" style={{ padding: '0 30px' }}>
            {COPY.homepage.join.headline}
          </h2>
          <br />
          <h4 className="text-black-tone-4">{COPY.homepage.join.copy}</h4>
          <div className="grid-x align-center">
            <ul
              className="text-left"
              style={{ listStyle: 'circle', padding: '0 1.5rem 0 1rem' }}
            >
              <li style={{ paddingTop: '1rem' }}>
                #1 fitness programs for women
                <b> to get results</b>
              </li>
              <li style={{ paddingTop: '1rem' }}>
                Easy-to-follow progress & daily reminders
                <b> to stay inspired</b>
              </li>
              <li style={{ paddingTop: '1rem' }}>
                At home workouts for all levels with top female trainers
                <b> to keep you accountable</b>
              </li>
              <li style={{ paddingTop: '1rem' }}>
                200+ healthy recipes & slimming meal plans
                <b> to feel and look amazing</b>
              </li>
            </ul>
          </div>
        </div>
        <div className="cell">
          <JoinCarousel />
        </div>
        <div className="cell small-10 small-offset-1 medium-6 medium-offset-3 large-4 large-offset-4 text-center">
          <Coupon />
        </div>
        <div className="cell" />
        <span>
          * Recurring billing, cancel anytime. Your one-time 7-day free trial
          starts after you enter payment details, and is for new users only.
          Valid credit or debit card required to activate trial. You’ll be
          charged for the subscription after trial expiration unless you cancel
          at least one day before free trial ends. Subscription renews
          automatically unless cancelled at least 24 hours prior to the end of
          the current subscription period. Cancel or manage your subscription
          any time. No cancellations or refunds will be provided for any unused
          portions of a subscription term.
        </span>
        <div className="cell desktop-only" />
      </GridX>
      <GridX
        containerClasses="border-bottom"
        gridClasses="grid-padding-x grid-padding-y"
      >
        <div className="cell" />
        <div className="cell">
          <h3 className="text-center text-blue-tone-3">
            {COPY.homepage.featuredHeadline}
          </h3>
        </div>

        <div className="cell mobile-only image-full">
          <img src={asSeenOnLogos} alt="" />
        </div>

        <div className="cell desktop-only medium-10 medium-offset-1 image-full">
          <img src={asSeenOnLogosDesktop} alt="" />
        </div>
        <div className="cell" />
      </GridX>
    </div>
  </Layout>
);

export default IndexPage;
