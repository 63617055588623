/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import COPY from '../../copy';
import Carousel from '..';
import Style from './testimonial-carousel.module.scss';

class TestimonialCarousel extends React.Component {
  importAll = r => {
    return r.keys().map(r);
  };

  mergeTwoArrays = (arr1, arr2) =>
    arr2.reduce(
      (next, arr, index) => [...next, { image: arr1[index] }, arr2[index]],
      []
    );

  render() {
    const images = this.importAll(
      require.context(
        '../../../assets/images/transformations',
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    const mergedArray = this.mergeTwoArrays(
      images,
      COPY.carousels.testimonials.slides
    );

    return (
      <Carousel {...this.props}>
        {mergedArray.map((val, index) => (
          <div className={Style.slide} key={index}>
            <div className={Style.innerSlide}>
              {val.image ? (
                <img alt="" src={val.image} />
              ) : (
                <>
                  <h2>{val.headline}</h2>
                  <p>{val.author}</p>
                </>
              )}
            </div>
          </div>
        ))}
      </Carousel>
    );
  }
}

TestimonialCarousel.defaultProps = {
  imposedSettings: {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000
  }
};

export default TestimonialCarousel;
